import React from "react"
import Layout from "../components/Layout"
import Title from "../components/Title/Title"
import DynamicContent from "../components/DynamicContent/DynamicContent"
import Tabs from "../components/Tabs/Tabs"
import DownloadButton from "../components/DownloadButton/DownloadButton"
import CtaDescList from "../components/CtaDescList/CtaDescList"
import { TwoColumns } from "../components/TwoColumns/TwoColumns"
import { GtagHead } from "../components/GTAG"
export function Head() {
  return (
    <>
      <GtagHead />
      <title>
        Resources for Patients & HCPs | LOQTORZI® (toripalimab-tpzi)
      </title>
      <meta
        name="description"
        content="Resources to help support you and your patients with the LOQTORZI treatment journey"
      />
    </>
  )
}

export default function Resources() {
  const tabPreparationAndStorage = [
    {
      title: (
        <span>
          For HCP<small>s</small>
        </span>
      ),
      text: (
        <section>
          <h3 className="text-blue-900 text-center">Prescribing Information</h3>
          <p className="mt-[1.3rem] font-MontserratRegular text-[1.6rem] text-center">
            The LOQTORZI Prescribing Information (PI).
          </p>
          <DownloadButton
            file={"/pdf/prescribing-information.pdf"}
            className="downloadableResource m-auto"
            title={<>Download the LOQTORZI Prescribing Information (PI)</>}
          >
            <span>DOWNLOAD</span>
          </DownloadButton>
          <h3 className="mt-[5.6rem] text-blue-900 text-center">
            Dosing and Administration Guide
          </h3>
          <p className="mt-[1.3rem] !mb-[1.3rem] font-MontserratRegular text-[1.6rem] text-center">
            A guide outlining dosing and administration information for
            LOQTORZI.
          </p>
          <DownloadButton
            file={"/pdf/loqtorzi-dosing-and-administration-guide.pdf"}
            className="downloadableResource m-auto"
            title={<>Download the LOQTORZI Dosing & Administration Guide</>}
          >
            <span>DOWNLOAD</span>
          </DownloadButton>
        </section>
      ),
    },
    {
      title: <span>For Patients</span>,
      text: (
        <section>
          <h3 className="text-blue-900 text-center">Get to Know LOQTORZI</h3>
          <p className="mt-[1.3rem] font-MontserratRegular text-[1.6rem] text-center">
            This brochure contains information to guide patients as they start
            treatment with LOQTORZI.
          </p>
          <TwoColumns className={"gap-[4.1rem]"}>
            <div>
              <DownloadButton
                file={"/pdf/patient-brochure.pdf"}
                className="downloadableResource m-auto mt-[1.5rem] tablet:min-h-[7.4rem] tablet:!flex tablet:justify-center tablet:items-center"
                title={<>Download the LOQTORZI brochure for patients</>}
              >
                <span>DOWNLOAD</span>
              </DownloadButton>
              <DownloadButton
                file={"/pdf/patient-brochure-spanish.pdf"}
                className="downloadableResource m-auto mt-[1.5rem] tablet:mt-[2.6rem]"
                title={<>Download the LOQTORZI brochure for patients</>}
              >
                <span>
                  DOWNLOAD
                  <br />
                </span>
                <span className="font-BarlowCondensedSemiBold text-[1.8rem] capitalize">
                  (Spanish version)
                </span>
              </DownloadButton>
            </div>
            <div>
              <DownloadButton
                file={"/pdf/patient-brochure-traditional-mandarin.pdf"}
                className="downloadableResource m-auto mt-[1.5rem]"
                title={<>Download the LOQTORZI brochure for patients</>}
              >
                <span>
                  DOWNLOAD
                  <br />
                </span>
                <span className="font-BarlowCondensedSemiBold text-[1.8rem] capitalize">
                  (Traditional Mandarin version)
                </span>
              </DownloadButton>
              <DownloadButton
                file={"/pdf/patient-brochure-simplified-mandarin.pdf"}
                className="downloadableResource m-auto mt-[1.5rem] tablet:mt-[2.6rem]"
                title={<>Download the LOQTORZI brochure for patients</>}
              >
                <span>
                  DOWNLOAD
                  <br />
                </span>
                <span className="font-BarlowCondensedSemiBold text-[1.8rem] capitalize">
                  (Simplified Mandarin version)
                </span>
              </DownloadButton>
            </div>
          </TwoColumns>
        </section>
      ),
    },
  ]

  const ctaDescriptionListData = [
    {
      cta: {
        text: "Get Updates",
        url: "/get-updates",
      },
      text: (
        <div>
          Register to receive the <br className="desktop:hidden" />
          latest information and <br className="desktop:hidden" />
          announcements about treatment.
        </div>
      ),
    },
    {
      cta: {
        text: <span>LOQTORZI Solutions™</span>,
        url: "/pdf/loqtorzi-solutions-enrollment-form.pdf",
        target: "_blank",
        type: "pdf",
      },
      text: (
        <div>
          See how LOQTORZI Solutions™
          <br className="desktop:hidden" /> can help your patients access
          financial
          <br className="desktop:hidden" /> support and treatment.
        </div>
      ),
    },
    {
      cta: {
        text: <>LOQTORZI at a glance</>,
        url: "/loqtorzi-at-a-glance/",
      },
      text: (
        <div>
          This module offers you quick <br className="desktop:hidden" />
          access to key information <br className="desktop:hidden" />
          about LOQTORZI <br className="hidden large:block" />
          to help you <br className="desktop:hidden" />
          support your patients.
        </div>
      ),
    },
  ]

  return (
    <Layout>
      <Title pageName={"Access & Resources"}>
        Loqtorzi <br className="tablet:hidden" />
        Resources
      </Title>

      <DynamicContent
        header={
          <h2 className="tablet:!py-[.75rem] large:!py-[1.6rem]">
            <span>
              Resources to help support you and your patients with the LOQTORZI
              treatment journey
            </span>
          </h2>
        }
      >
        <Tabs
          className="py-[3rem] tablet:ml-[3rem]"
          content={tabPreparationAndStorage}
          isBorderLeft={false}
        />
      </DynamicContent>

      <CtaDescList content={ctaDescriptionListData} />
    </Layout>
  )
}
